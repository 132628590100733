import React from 'react';
import {Alert, Button, Form, Input, message, Radio, Spin, Tooltip} from 'antd';
import {Copy} from '@styled-icons/boxicons-regular/Copy';
import styled from 'styled-components';
import {getOutlet, useOutlet} from 'reconnect.js';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
// import AntdAddressSetForm from 'rev.sdk.js/Components/AntdAddressSetForm';
import ResetEmailButton from 'rev.sdk.js/Components/ResetEmailButton';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as Config from '../../../data.json';

const VB_MEMBERSHIP_MAP = {
  level_0: '註冊會員',
  level_1: '微媞之友',
  level_2: '微媞白鑽會員',
  level_3: '微媞粉鑽會員',
};

function ProfilePage(props) {
  const [user, setUser] = useOutlet('user');
  const [form] = Form.useForm();
  const [values, setValues] = React.useState({});
  const [referByInfo, setReferByInfo] = React.useState(null);
  const hasReferBy = user.data && user.data.refer_by;

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      AppActions.setLoading(true);
      const resp = await JStorageActions.updateDocument(
        'user_profile',
        {
          id: user.data.id,
        },
        values,
      );
      setUser({
        ...user,
        data: {
          ...user.data,
          ...resp,
        },
      });
      message.success('儲存成功！');
    } catch (ex) {
      message.error(`API error ${ex}`);
    } finally {
      AppActions.setLoading(false);
    }
  };

  React.useEffect(() => {
    if (Config.allowReferBy && hasReferBy) {
      const fetchReferByInfo = async () => {
        setReferByInfo(
          await JStorageActions.fetchOneDocument('user_profile', {
            owner: user.data.refer_by,
          }),
        );
      };

      fetchReferByInfo();
    }
  }, [hasReferBy, user.data?.refer_by]);

  return (
    <Wrapper>
      <h2>個人資訊</h2>

      {user.data ? (
        <Form
          name="control-hooks"
          form={form}
          initialValues={{
            ...user.data,
          }}
          onValuesChange={(changedValues, allValues) => {
            setValues({...allValues, ...changedValues});
          }}
          onFinish={onFinish}>
          {/* vb doesn't need to use it. */}
          {/* 
          {Config.allowReferBy && hasReferBy && (
            <Form.Item label="推薦人" style={{marginBottom: 10}}>
              {referByInfo
                ? referByInfo.name || referByInfo.owner
                : user.data.refer_by || '-- --'}
            </Form.Item>
          )}

          {user.data.points !== undefined &&
            getOutlet('FeatureOptions').getValue().points && (
              <Form.Item label="我的紅利" name="points">
                <span style={{color: '#d11d26', fontWeight: 'bold'}}>
                  {user.data.points}
                </span>
                <span>點</span>
              </Form.Item>
            )} */}

          {/* vb use these components. 會員等級、會員期限、微媞幣 start*/}
          <div style={{margin: 20}}>
            {user.data.membership !== undefined && (
              <Form.Item label="" name="membership" noStyle>
                <div>
                  <span style={{marginRight: 5}}>我的會員等級：</span>
                  <span style={{color: '#b8425f', fontWeight: 'bold'}}>
                    {VB_MEMBERSHIP_MAP[user.data.membership]}
                  </span>
                </div>
              </Form.Item>
            )}
            {user.data.membership_end_date !== undefined && (
              <Form.Item label="" name="" noStyle>
                <div>
                  <span>我的會員等級期限：</span>
                  <span
                    style={{
                      color: '#b8425f',
                      fontWeight: 'bold',
                    }}>
                    {(user.data.membership === 'level_0' ||
                      user.data.membership === 'level_1') &&
                      '永久'}
                    {user.data.membership === 'level_2' &&
                      user.data.membership === 'level_3' &&
                      new Date(user.data.membership_end_date)
                        .toISOString()
                        .slice(0, 10)
                        .replace('T', ' ')}
                  </span>
                </div>
              </Form.Item>
            )}
            {user.data.points !== undefined &&
              getOutlet('FeatureOptions').getValue().points && (
                <Form.Item label="" name="points" noStyle>
                  <div>
                    <span style={{margiRight: 5}}>我的微媞幣：</span>
                    <span
                      style={{
                        marginRight: 5,
                        color: '#d11d26',
                        fontWeight: 'bold',
                      }}>
                      {user.data.points}
                    </span>
                    <span>點</span>
                  </div>
                </Form.Item>
              )}
          </div>
          {/* vb use these components. 會員等級、會員期限、微媞幣 end*/}

          <Form.Item
            label="姓名"
            name="name"
            rules={[{required: true, message: '請輸入您的姓名'}]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="性別"
            name="gender"
            rules={[{required: true, message: '請選擇您的性別'}]}>
            <Radio.Group>
              <Radio value={true}>先生</Radio>
              <Radio value={false}>小姐</Radio>
            </Radio.Group>
          </Form.Item>

          {/* vb has birth field */}
          <Form.Item label="我的生日" name="birth">
            <input
              disabled={!!user.data.birth}
              type="date"
              id="birth"
              name="birth"
              valuePropName="birth"
              value={values.birth || user.data.birth}
              onChange={(e) => {
                // workaround: we use html date input instead of antd calendar, bcuz antd use moment for date value, but we need "YYYY-MM-DD" data structure
                setValues({...values, birth: e.target.value});
              }}
              style={{
                borderRadius: 10,
                padding: '4px 11px',
                border: '1px solid #d9d9d9｀',
              }}
            />
            <div style={{padding: 10, color: '#808080'}}>
              ** 請注意，生日修改後無法再作修改！如需修改請洽詢客服 **
            </div>
          </Form.Item>

          <Form.Item
            label="手機"
            name="phone"
            rules={[
              {
                required: true,
                pattern: /^[0-9]{10}$/,
                message: '請輸入您的手機號碼，共10碼',
              },
            ]}>
            <Input
              //vb need to disable this field whean user have birth field
              disabled={user.data.birth}
            />
          </Form.Item>

          <Form.Item
            label="電子信箱"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: '請輸入您的電子信箱',
              },
            ]}>
            <Input disabled />
          </Form.Item>

          {/* vb doesn't need to use it. */}
          {/* {!user.data.email && (
            <Alert
              style={{marginBottom: 10}}
              showIcon={true}
              message="尚未驗證電子信箱，請點擊下方按鈕「驗證 ｜ 更改電子信箱」進行驗證。"
              type="error"
            />
          )} */}

          {/* vb doesn't need to use it. */}
          {/* <AntdAddressSetForm form={form} /> */}

          {user.data.provider !== 'default' && (
            <Form.Item label="登入方式" name="provider">
              <Input disabled />
            </Form.Item>
          )}

          <Form.Item
            label="動作"
            style={{display: 'flex', alignItems: 'center'}}>
            <Button htmlType="button" style={{margin: 5}} onClick={onReset}>
              重設
            </Button>
            <Button style={{margin: 5}} htmlType="submit" type="primary">
              儲存
            </Button>
            <ResetEmailButton style={{margin: 5}} />
          </Form.Item>

          {Config.allowReferBy && (
            <Form.Item
              label="邀請碼連結"
              style={{borderTop: '1px solid #eeeeee', paddingTop: 20}}>
              <div style={{display: 'flex'}}>
                <Input
                  style={{marginRight: 10}}
                  disabled={true}
                  value={`${Config.webHost}/register/request?refer_by=${user.data.owner}`}
                />
                <Tooltip title="複製連結">
                  <Button
                    shape="circle"
                    icon={<Copy size={18} />}
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `${Config.webHost}/register/request?refer_by=${user.data.owner}`,
                      );
                      message.success('複製成功！');
                    }}
                  />
                </Tooltip>
              </div>
            </Form.Item>
          )}
        </Form>
      ) : (
        <Spin />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
`;

export default withPageEntry(ProfilePage);
